import { APP_ROUTES } from "./app-routes"
export const CONSTANTS: any = {
    LOADING_ANIMATION_COLOR: '#40bd9c',
    MENU: [
        // { name: 'How It Work', href: `${APP_ROUTES.HOME}#howitwork` },
        // { name: 'Garage Near You', href: APP_ROUTES.GARAGE },
        // { name: 'Why Choose Us', href: `${APP_ROUTES.HOME}#chooseus` }
    ],
    STICK_MENU: [
        { name: 'Why GoCar Garage', href: `${APP_ROUTES.HOME}`, region: 'whygogarage' },
        { name: 'Our Service Packages', href: `${APP_ROUTES.HOME}`, region: 'ourservice' },
        { name: 'How to Book', href: `${APP_ROUTES.HOME}`, region: 'howtobook' },
        { name: 'Garages Near You', href: `${APP_ROUTES.GARAGE}` },
        { name: 'Get A Quote', href: `${APP_ROUTES.CONSULTATION}` },
        { name: 'Blog', href: 'https://blog.gocargarage.my', blank: true }
    ],
    BOOKING_SOURCE: {
        garage_website: 'garage_website'
    },
    BOOKING_TYPE: {
        NORMAL: { value: 'normal', name: 'Normal' },
        BUSINESS: { value: 'business', name: 'Business' },
    },
    ONE_DAY_IN_MILLISECONDS: 1 * 24 * 60 * 60 * 1000,
    BOOKING_STATUS: {
        PENDING: { value: 'pending', name: 'Pending for Confirmation', order: 1, isAutoUpdate: true },
        APPOINTMENT_CONFIRMED: { value: 'appointment_confirmed', name: 'Appointment Confirmed', order: 2 },
        APPOINTMENT_CANCELLED: { value: 'appointment_cancelled', name: 'Appointment Cancelled', order: 3, isStop: true },
        APPOINTMENT_REJECTED: { value: 'appointment_rejected', name: 'Appointment Rejected', order: 4, isStop: true },
        PICK_UP_VEHICLE: { value: 'pick_up_vehicle', name: 'Pick-up Vehicle', order: 5 },
        VEHICLE_ARRIVED_GARAGE: { value: 'vehicle_arrived_garage', name: 'Vehicle arrived at Garage', order: 6 },
        VEHICLE_CHECKING: { value: 'vehicle_checking', name: 'Vehicle Checking', order: 7 },
        QUOTATION_GENERATED: { value: 'quotation_generated', name: 'Quotation Generated', order: 8, isAutoUpdate: true },
        QUOTATION_PENDING_APPROVAL: { value: 'quotation_pending_approval', name: 'Quotation Pending for Approval', order: 9, isAutoUpdate: true }, // Business Only
        QUOTATION_REJECTED: { value: 'quotation_rejected', name: 'Quotation Rejected', order: 10, isStop: true, isAutoUpdate: true },
        QUOTATION_APPROVED: { value: 'quotation_approved', name: 'Quotation Approved', order: 11, isAutoUpdate: true },
        PROCEED_REPAIR: { value: 'proceed_repair', name: 'Proceed Repair', order: 12 },
        JOB_DONE: { value: 'job_done', name: 'Job Done', order: 13 },
        INVOICE_GENERATED: { value: 'invoice_generated', name: 'Invoice Generated', order: 14, isAutoUpdate: true },
        INVOICE_PENDING_APPROVAL: { value: 'invoice_pending_approval', name: 'Invoice Pending for Approval', order: 15, isAutoUpdate: true }, // Business Only
        INVOICE_APPROVED: { value: 'invoice_approved', name: 'Invoice Approved', order: 16, isAutoUpdate: true }, // Business Only, Customer approve invoice via CMS
        INVOICE_REJECTED: { value: 'invoice_rejected', name: 'Invoice Rejected', order: 17, isAutoUpdate: true }, // Business Only, Customer approve invoice via CMS
        LPO_SUBMITTED: { value: 'lpo_submitted', name: 'LPO Submitted', order: 18, isAutoUpdate: true }, // Business Only, Customer upload LPO via CMS
        PAYMENT_RECEIVED: { value: 'payment_received', name: 'Payment Received', order: 19 },
        CAR_DELIVERING: { value: 'car_delivering', name: 'Car Delivering', order: 20 },
        CAR_DELIVERED: { value: 'car_delivered', name: 'Car Delivered', order: 21 },
        CAR_COLLECTION_PENDING: { value: 'car_collection_pending', name: 'Car Collection Pending', order: 22 }, // Merchant app change
        CAR_COLLECTED: { value: 'car_collected', name: 'Car Collected', order: 23 }, // Merchant app change
        GOVALET_JOB_ACCEPTED: { value: 'govalet_job_accepted', name: 'GoValet Job Accepted', order: 24 }, // update from GoValet
        GOVALET_ARRIVED_AT_LOCATION: { value: 'govalet_arrived_at_location', name: 'Arrived At Location', order: 25 }, // update from GoValet
        COMPLETED: { value: 'completed', name: 'Completed', order: 26 }
    },
    DOWNLOAD: {
        IOS: 'https://apps.apple.com/my/app/gocar-malaysia/id1098123088',
        ANDROID: 'https://play.google.com/store/apps/details?id=my.gocar.app'
    },
    BOOKING_PICKUP_DELIVER_METHOD: {
        WALK_IN: { value: 0, name: 'Walk-in', disabled: false },
        GO_VALET: { value: 1, name: 'GoValet', disabled: false }
    },
    MARKER_NORMAL: {
        icon_available: {
            "url": "https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-2.png?alt=media&token=46602c9b-ef4d-40d7-894e-adb3002d357b",
            "size": { "width": 32, "height": 45 }
        },
        icon_not_available: {
            "url": "https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-grey.png?alt=media&token=1b2240d6-978c-4e5d-aa2f-3c3ec5001656",
            "size": { "width": 32, "height": 45 }
        },
        icon_selected: {
            "url": "https://firebasestorage.googleapis.com/v0/b/gocar-staging-917f5.appspot.com/o/markers%2FG-icon-selected.png?alt=media&token=5c2de7d7-da81-41eb-8b55-64927b3a30d7",
            "size": { "width": 32, "height": 39 }
        },
        icon_user: {
            "url": "./assets/img/marker/userlocation.png",
            "size": { "width": 32, "height": 45 }
        }
    },
    PHONE_NUMBER: /^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/,
    // number start with 11 have 10 digit, number that not start with 11 have 9 digit
    GOCAR_HQ_LOCATION: {
        lat: 3.1133528,
        lng: 101.6350825
    },
    GOCAR_PREFERRED: 'GoCar Preferred',
    VISITOR: 'visitor',
}
