import { Injectable } from '@angular/core';
import { UtilsService } from './utils/utils.service';
import { environment } from 'src/environments/environment';
import { CONSTANTS } from '../common/constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as CryptoJS from 'crypto-js';

declare var window: any;
declare const showChat: any;

@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  freshChatConfig: any
  isLoadedChatJS = false

  constructor(
    private utilsService: UtilsService,
    private deviceService: DeviceDetectorService
  ) {
    // get config
    if (environment.gocar_support) {
      this.freshChatConfig = environment.gocar_support
    } else {
      console.error(new Error("missing config for gocar_support"))
      this.utilsService.showError("missing config for gocar support")
    }
  }

  async showChatbox() {
    if (this.freshChatConfig && this.freshChatConfig.isUseGoCarChatWebsite) {
      return this.loadChatJs()
    } else {
      return this.loadFreshChat()
    }
  }

  async loadChatJs() {
    return new Promise(async (res, rej) => {
      // prevent scoll for mobile view
      // document.body.classList.add('noscrolling');

      if (this.isLoadedChatJS) {
        this.updateUserInfo();
        showChat()
        return res(true)
      }

      if (!this.freshChatConfig || !this.freshChatConfig.goCarChatWebsiteUrl || !this.freshChatConfig.goCarChatJSUrl) {
        this.utilsService.showError('Loading chat service fail. please try again')
        return res(false)
      }

      let visitorId = this.utilsService.getLocalStorage(CONSTANTS.VISITOR);

      if (!visitorId) {
        visitorId = CryptoJS.lib.WordArray.random(16).toString();
        this.utilsService.setLocalStorage(CONSTANTS.VISITOR, visitorId);
      }

      // this.loadingService.show()

      let GOCAR_CHAT_WEBSITE_URL = this.freshChatConfig.goCarChatWebsiteUrl
      let GOCAR_CHAT_JS = this.freshChatConfig.goCarChatJSUrl + `?time=${new Date().getMilliseconds()}`
      let userInfo: any = this.utilsService.getLocalStorage('user_info');
      userInfo = JSON.parse(userInfo as string);

      console.log("userInfo ---> ", userInfo)

      let userLocation
      let deviceInfo

      try {
        userLocation = await this.utilsService.getCurrentLocation()
      } catch (error) {
        console.log("get location error: ", error)
        userLocation = {
          lat: CONSTANTS.GOCAR_HQ_LOCATION.lat,
          lng: CONSTANTS.GOCAR_HQ_LOCATION.lng
        }
      }

      try {
        deviceInfo = this.deviceService.getDeviceInfo();
        console.log("deviceInfo ====> ", deviceInfo)
      } catch (error) {
        console.log("get deviceInfo error: ", error)
        deviceInfo = {
          browser: "web",
          device: 'none'
        }
      }

      if (userInfo) {
        GOCAR_CHAT_WEBSITE_URL = GOCAR_CHAT_WEBSITE_URL + `?platform=${deviceInfo?.browser}&model=${deviceInfo?.device}&lat=${userLocation?.lat}&lng=${userLocation?.lng}&firstName=${userInfo.firstName}&lastName=${userInfo.lastName}&phoneNumber=${userInfo.phoneNumber}&service=garage&userId=${userInfo.userId}&visitorId=${visitorId}`
      } else {
        GOCAR_CHAT_WEBSITE_URL = GOCAR_CHAT_WEBSITE_URL + `?platform=${deviceInfo?.browser}&model=${deviceInfo?.device}&lat=${userLocation?.lat}&lng=${userLocation?.lng}&visitorId=${visitorId}`
      }

      const chatJS = document.getElementById('chatJS')
      if (!chatJS) {
        const script = document.createElement('script');
        script.src = GOCAR_CHAT_JS
        document.body.appendChild(script);
      }

      let iframe
      let times = 0
      let maxTimes = 10
      while (!iframe && times < maxTimes) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        iframe = document.getElementById('gocarChatIframe') as HTMLIFrameElement;
        ++times
        console.log("LOOP TIMES =====> ", times)
        console.log("iframe =====> ", iframe)
      }

      if (iframe) {
        iframe.src = GOCAR_CHAT_WEBSITE_URL;
      } else {
        this.utilsService.showError("Loading chat service fail. please try again.")
        return res(false)
        // this.loadingService.hide()
      }

      this.updateUserInfo();

      window.addEventListener('message', (event: MessageEvent) => {
        // load iframe done
        if (event.data === 'iframeChatLoadSuccess') {
          console.log('Iframe load success. APP_LELVEL');
          // this.loadingService.hide()
          this.isLoadedChatJS = true
          showChat()
          return res(true)
        }

        if (event.data === 'iframeChatLoadError') {
          console.log('Iframe load error. APP_LELVEL');
          // this.loadingService.hide()
          this.utilsService.showError("Loading chat service fail. please try again!")
          return res(false)
        }

        if (event.data === 'closeIframe') {
          document.body.classList.remove('noscrolling');
        }
      }, false);
    })
  }

  private updateUserInfo() {
    let result: any = this.utilsService.getLocalStorage('user_info');
    if (!result) {
      return;
    }

    result = JSON.parse(result as string);

    const iframe = document.getElementById('gocarChatIframe') as HTMLIFrameElement;
    const chatWindow = iframe?.contentWindow;

    chatWindow?.postMessage({
      key: 'update_user_info',
      phoneNumber: result.telephone,
      service: 'sharing',
      ...result,
    }, '*')
  }

  loadFreshChat() {
    return new Promise(async (res, rej) => {
      try {
        // this.loadingService.show(120);

        // init widget
        if (this.freshChatConfig && this.freshChatConfig.isShowFs) {
          await this.initWidget()
        }

        // widget is loaded
        this.setUserInfo();

        // show widget
        if (window['fcWidget'].isInitialized()) {
          window['fcWidget'].open()
          window['fcWidget'].show()
        }
      } catch (error) {
        console.log("showChatbox error: ", error)
        rej(error);
      } finally {
        // this.loadingService.hide()
      }
    })
  }

  private async initWidget() {
    if (!window['fcWidget'].isInitialized()) {
      if (this.freshChatConfig) {
        window['fcWidget'].init({
          token: this.freshChatConfig.token,
          host: this.freshChatConfig.host,
          config: this.freshChatConfig.config || {}
        })
      }

      // listen when init freshchat done
      // wait for 1s to check if widget is loaded
      while (window['fcWidget'].isLoaded() == false) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }

  private setUserInfo() {
    const result = this.utilsService.getLocalStorage('user_info');
    if (result) {
      const userInfo = JSON.parse(result)
      window['fcWidget'].setExternalId(userInfo.email);
      window['fcWidget'].user.setEmail(userInfo.email);
      window['fcWidget'].user.setFirstName(userInfo.firstName);
      window['fcWidget'].user.setPhone(userInfo.phoneNumber);
    }
  }
}
